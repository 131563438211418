@charset "utf-8";
/* CSS Document */
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}
body, ol, ul, li, div, p, span, form, h1, h2, h3, h4, h5, h6, table, thead, th, tbody, tr, td, img, input, textarea, dd, dt, dl{word-wrap:break-word;outline:none;padding:0;margin:0;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);}
textarea{overflow:auto;-webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}
h1, h2, h3, h4, h5, h6, p, ul, ol, dd, dt, dl{-webkit-margin-before:0px;-webkit-margin-after:0px;-webkit-margin-start:0px;-webkit-margin-end:0px;}
a img, img{border:none;outline:none;}
// input[type=submit], input[type=reset], input[type=button], button{ -webkit-appearance:none; -moz-appearance:none;-ms-appearance:none; appearance:none; }
// form input[type='radio'], form input[type='checkbox']{width: 0;height: 0;}
a{cursor:pointer;text-decoration:none;outline:0;}
.no-script-msg{font:12px Arial, verdana, Helvetica, sans-serif;background:#FFFFCC;width:auto;padding:10px 10px 10px 25px;margin:8px;border:1px solid #CD3D18;font-weight:bold;height:auto;font-size:11px;color:#000000;line-height:150%;clear:both;}
// input{-webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; font-family: 'arial', sans-serif; -webkit-appearance:none; }
body{ min-width:300px; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; -ms-font-smoothing:antialiased; background-color: #fff;}
::selection {text-shadow: none;background: rgba(65,131,196,0.4);}
.clear{clear:both; height:0px; overflow:hidden; width:auto; display:block; float:none !important;}
ul, menu, dir {margin:0px;padding:0px;display: block;list-style-type: disc;-webkit-margin-before: 0;-webkit-margin-after: 0;-webkit-margin-start: 0;-webkit-margin-end: 0;-webkit-padding-start: 0;}
*, *:after , *:before { -moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box;}
li{list-style: none;}
// 
body p { margin-bottom: 0; }


//font-family: 'Roboto', sans-serif;

@font-face {
    font-family: 'Helvetica Regular';
    src:url('/fonts/HelveticaNowText-Regular.woff') format('woff'), url('/fonts/HelveticaNowText-Regular.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
}
@font-face {
    font-family: 'Helvetica Medium';
    src:url('/fonts/HelveticaNowText-Medium.woff') format('woff'),url('/fonts/HelveticaNowText-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'Helvetica Bold';
    src:url('/fonts/HelveticaNowText-Bold.woff') format('woff'),url('/fonts/HelveticaNowText-Bold.woff2') format('woff2') ;
}
@font-face {
    font-family: 'Helvetica ExtraBold';
    src:url('/fonts/HelveticaNowText-ExtraBold.woff') format('woff'),url('/fonts/HelveticaNowText-ExtraBold.woff2') format('woff2') ;
}

*,
body {
    margin: 0;
    padding: 0;
}
body{
    background-color: $bodyBg !important;
    overflow-x: hidden;
}
h1 {
    font-size: 22px ;
    @include mediaq(1200px) {
        font-size: 20px ;
    }
}
h2 {
    font-size: 20px  ;
    @include mediaq(1200px) {
        font-size: 18px ;
    }
    // @include mediaq(991px) {
    //     font-size: 16px  ;
    // }

}
h3 {
    font-size: 18px  ;
    @include mediaq(1200px) {
        font-size: 16px ;
    }
}
h4 {
    font-size: 15px  ;
}
img,iframe,picture {
    max-width: 100%;
    display: block;
}
table{width: 100%;}
p,span,a{
    font-size: 14px;
    font-family: $HvRegular;
}
.mb_50{
    margin-bottom: 50px;
}
.mb_25{
    margin-bottom: 25px;
}
.mb_15{
    margin-bottom: 15px;
}
.mb_10{
    margin-bottom: 10px;
}
.bg_white{
    background: $white;
}
.bg_blue_btn{
    background: $blue_btn;
    color: $white;
    font-size: 14px;
    width: 80px;
    border: 1px solid $blue_btn;
    &:hover{
        background: $white;
    }
}
.light_red_btn{
    background: $light_errorBg;
    color: $remove;
    width: 80px;
    font-size: 14px;
    border: 1px solid $light_errorBg;
    &:hover{
        border-color: $remove;
    }
}
.green_text {
    color: $green;
}
.link_color{
    color: $blue_btn;
    &.pointer{
        cursor: pointer;
    }    
}
.btn_bg{
    background: $linkcolor !important;
    border:1px solid $linkcolor !important;
    min-width: 130px;
    display: inline-block;
    text-align: center;
    color: $white;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    // box-shadow:  0 10px 15px rgba(70,181,209,0.1);
    @include mediaq(576px) {
        min-width: 100px;
        font-size: 14px;
        padding: 5px 10px;
    }
}
.btn_bg2{
    background-color: $blue_btn !important;
    border:1px solid $blue_btn !important;
    min-width: 130px;
    display: inline-block;
    padding: 12px;
    color: $white;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    box-shadow:  0 10px 15px rgba(70,181,209,0.1);
    @include mediaq(576px) {
        min-width: 100px;
        font-size: 14px;
        padding: 5px 10px;
    }
}
.btn_green{
    background-color: $green !important;
    border:1px solid $green !important;
    color: $white !important;
    display: inline-block;
    text-align: center;
    min-width: 130px;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    @include mediaq(576px) {
        min-width: 100px;
        font-size: 14px;
        padding: 5px 10px;
    }
}
.light_danger_btn{
    background-color: $light_errorBg !important;
    border:1px solid $light_errorBg !important;
    color: $white !important;
    display: inline-block;
    text-align: center;
    padding: 12px;
    border-radius: 4px;
    min-width: 130px;
    cursor: pointer;
    font-size: 16px;
    line-height:21px;
    font-family: $Roboto;
    @include mediaq(576px) {
        min-width: 100px;
        font-size: 14px;
        padding: 5px 10px;
    }
}
.common_time_icon{
    position: relative;
    &:before{
        background: url("/web_images/clock_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 17px;height: 17px;
        content: "";
        margin-right: 10px;
    }
}
.common_date_icon{
    position: relative;
    &:before{
        background: url("/web_images/date_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 17px;height: 17px;
        content: "";
        margin-right: 10px;
    }
}
.common_link_icon{
    position: relative;
    &:before{
        background: url("/web_images/link_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 17px;height: 17px;
        content: "";
        margin-right: 10px;
    }
}
.common_click_icon{
    position: relative;
    &:before{
        background: url("/web_images/click_icon.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 14px;height: 20px;
        content: "";
        margin-right: 10px;
    }
}
.common_team_icon{
    position: relative;
    &:before{
        background: url("/web_images/user_icons.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 14px;height: 20px;
        content: "";
        margin-right: 10px;
    }
}
.common_softball_icon{
    position: relative;
    &:before{
        background: url("/web_images/softball.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 14px;height: 20px;
        content: "";
        margin-right: 10px;
    }
}
.common_export_icon{
    position: relative;
    &:before{
        background: url("/web_images/export.svg") no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        width: 14px;height: 20px;
        content: "";
        margin-right: 10px;
    }
}
.overflow_auto{
    overflow: auto;
    // box-shadow:  0 10px 15px rgba(70,181,209,0.1);
}
.error{
    color: $error !important;
}
.green{
    color: $green;
}
.select_custom {
    position: relative;
    select {
        -webkit-appearance: none;
        width: 100%;
        padding: 9px 25px 9px 11px;
        border-radius: 4px;
        border: 1px solid $border_1;
        font-size: 16px;
        line-height: 20px;
        font-family: $HvRegular;
        outline: none;
        option {
            &:disabled{
                color: $placeHolder;
            }
        }
    }
    .angle_down {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        color: $dropdowntext;
        pointer-events: none;
    }
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $darkgray; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:$fontText3; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $fontText4; 
  }