// Event page Header
.web_container_1290 {
  max-width: 1310px;
  padding: 0 10px;
  margin: auto;
  width: 100%;
}

.ab_loader {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 2;
  @include display_flex;
  @include align_center;
  @include just_center;
  height: 100%;
  z-index: 99999;
  svg {
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.ab_loader_abs {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  z-index: 2;
  @include display_flex;
  @include align_center;
  @include just_center;
  height: 100%;
  z-index: 99999;
  svg {
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.container_900 {
  max-width: 920px;
  padding: 0 10px;
  margin: auto;
  width: 100%;
}

.web_main {
  position: relative;
  padding: 20px 0;
  &.inner_pages {
    padding: 40px 0 260px;
    @include mediaq(768px) {
      padding: 40px 0 100px;
    }
  }
  @include mediaq(768px) {
    padding: 40px 0 40px;
  }
}

.web_banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $black;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.search_form {
  @include mediaq(480px) {
    width: 100%;
    margin: 0 auto 20px;
  }
  .web_search {
    position: relative;
    margin: 0 28px;
    &.active {
      i {
        color: $black;
        @media screen and (max-width: 1200px) and (min-width: 992px) {
          color: $white;
        }
      }
      input {
        opacity: 1;
        display: block;
        @media screen and (max-width: 1200px) and (min-width: 992px) {
          left: -10px;
          top: 30px;
        }
      }
    }
    i {
      color: $white;
      cursor: pointer;
      display: inline-block;
      position: relative;
      z-index: 3;
      font-size: 14px;
      @include mediaq(480px) {
        // display: none;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $black;
      }
    }
    input {
      position: absolute;
      top: -10px;
      opacity: 0;
      width: 200px;
      right: -10px;
      z-index: 2;
      font-size: 15px;
      height: 35px;
      padding-right: 20px;
      transition: 0.3s ease;
      display: none;
      @include mediaq(480px) {
        left: 0;
        opacity: 1;
        display: block;
        position: static;
        width: 100%;
        margin: 0px;
      }
    }
  }
}

.web_container {
  padding: 0 30px;
  max-width: 100%;
  width: 100%;
  margin: auto;
  position: relative;
  @include mediaq(768px) {
    padding: 0 10px;
  }
  .diamond_logo {
    @include mediaq(480px) {
      margin: 0 30px 20px;
    }
    a {
      padding: 0;
    }
    img {
      max-width: 115px;
      max-height: 77px;
    }
  }
  .web_menu_toggle {
    position: relative;
    margin-right: 45px;
    @include mediaq(480px) {
      position: absolute;
      order: 1;
      top: 0;
      right: 0;
      left: 0;
    }
    .web_menu_toggleBtn {
      width: 20px;
      height: 15px;
      position: relative;
      cursor: pointer;
      display: none;
      @include mediaq(991px) {
        display: block;
      }
      @include mediaq(480px) {
        position: absolute;
        right: 0;
        top: 25px;
      }
      span {
        width: 100%;
        height: 2px;
        background: $white;
        display: block;
        position: absolute;
        transition: 0.3s ease;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          transform: translateY(-50%);
          top: 50%;
        }
        &:nth-child(4) {
          bottom: 0;
        }
      }
    }
    .nav {
      @include mediaq(991px) {
        display: none;
        transition: 0.5s ease;
      }
      @include mediaq(480px) {
        position: absolute;
        left: 0;
        right: 0;
      }
    }
    &.active {
      .web_menu_toggleBtn {
        span {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
      .nav {
        @include mediaq(991px) {
          position: absolute;
          top: 30px;
          right: 0;
          display: block;
          visibility: visible;
          width: 100%;
          @include just_center;
          width: 150px;
          background: $black;
          border-radius: 5px;
          z-index: 3;
        }
        @include mediaq(480px) {
          left: 20px;
          right: 0;
          width: 100%;
          top: 50px;
        }
      }
    }
  }
  .web_menu {
    @include mediaq(480px) {
      width: 100%;
    }
    .web_menu_inner {
      @include mediaq(480px) {
        @include just_center;
      }
      .dropdown-menu {
        font-size: 16px;
        &.show {
          width: 100%;
        }
      }
    }
    ul {
      li {
        margin: 0 15px;
        @include mediaq(1130px) {
          margin: 0;
        }
        @include mediaq(576px) {
          width: 100%;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: $white;
          font-size: 15px;
          font-family: $HvRegular;
          &:hover {
            color: $linkcolor;
          }
        }
      }
    }
    .nav-link {
      color: $white;
      font-size: 15px;
      font-family: $HvRegular;
      &:hover {
        color: $linkcolor;
      }
    }
  }
}

.web_user {
  width: 44px;
  height: 44px;
  border: 3px solid $white;
  background: $white;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.month_year_wrapper {
  position: relative;
  margin-top: 60px;
  @include mediaq(540px) {
    margin-top: 30px;
  }
}

.month_year {
  margin-bottom: 50px;
  background: rgba(40, 38, 61, 0.9);
  position: relative;
}

.month_list {
  margin-bottom: 10px;
  li {
    padding: 0 14px;
    text-transform: uppercase;
    color: $lightgray2;
    font-size: 14px;
    font-family: $Hvmed;
    cursor: pointer;
    @include mediaq(540px) {
      margin-bottom: 10px;
    }
    &:hover {
      color: $white;
    }
    &.active {
      color: $white;
    }
  }
}

.day_list {
  li {
    color: $white;
    width: 45px;
    height: 45px;
    font-size: 15px;
    font-family: $Hvmed;
    position: relative;
    cursor: pointer;
    &:hover,
    &.active {
      background: $white;
      color: $darkpurple;
    }
    &.booked {
      &:after {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: $green;
        bottom: 5px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.web_top_filter {
  .web_top_filter_title {
    position: relative;
    color: $white;
    margin-bottom: 15px;
    padding: 0 15px;

    .select_dropdown {
      margin-left: 20px;
      .angle_down {
        color: $white;
      }
      select {
        color: $white;
        background: none;
        border: none;
        padding: 5px 35px 5px 5px;
        -webkit-appearance: none;
        option {
          color: $black;
        }
      }
    }
  }
}
.check_event {
  position: absolute;
  top: 10px;
  right: 25px;
  width: 30px;
  height: 30px;
  margin: 0;
  z-index: 99;
  box-shadow: 0 1px 4px rgba(51, 46, 65, 0.4);
  border-radius: 50%;
  .check {
    width: 100%;
    height: 100%;
    background: $white;
    border-radius: 50%;
    cursor: pointer;
  }
  input[type="checkbox"] {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    &:checked + .check {
      background: $blue_btn;
      &::after {
        content: "\f00c";
        font-family: $FontAwesome;
        color: $white;
        text-align: center;
        margin: auto;
        left: 0;
        right: 0;
        line-height: 30px;
        font-size: 16px;
        display: block;
      }
    }
  }
}
.event_card {
  height: 92%;
  background: $white;
  border: none;
  box-shadow: 0 2px 2px rgba(131, 146, 165, 0.1);
  overflow: hidden;
  transition: 0.3s ease;
  .event_card_img {
    position: relative;

    img {
      max-width: 100%;
      min-height: 200px;
      max-height: 200px;
      width: 100%;
      object-fit: cover;
    }
    .event_card_address {
      display: block;
      font-size: 14px;
      line-height: 23px;
      font-family: $HvRegular;
      padding: 4px 10px 4px 40px;
      position: absolute;
      right: 0;
      bottom: 0;
      color: $white;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 10px 0 0 0;
      &::before {
        content: "";
        background: url("/web_images/map_marker.svg") no-repeat;
        width: 12px;
        height: 16px;
        background-size: 100% 100%;
        left: 7px;
        z-index: 999;
        position: absolute;
        top: 7px;
      }
    }
  }
  .event_tags {
    position: absolute;
    right: 5px;
    top: 3px;
    span {
      background: $green;
      display: inline-block;
      padding: 2px 8px;
      color: $white;
      font-size: 11px;
    }
  }
  .event_card_desc {
    padding: 22px 10px 10px;
    @include display_flex;
    @include align_center;
    position: relative;
    .event_card_logo {
      margin-right: 12px;
      width: 78px;
      height: 78px;
      padding: 8px;
      @include mediaq(991px) {
        width: 60px;
        height: 60px;
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .event_card_titles {
      width: calc(100% - 90px);
      @include mediaq(991px) {
        width: calc(100% - 60px);
      }
      h3 {
        margin-bottom: 10px;
        font-family: $Hvextrabold;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
    .event_card_dates {
      @include display_flex;
      @include align_center;
      @include justify_sp;
      p {
        font-family: $Hvmed;
        position: relative;
        padding-right: 10px;
        margin-bottom: 5px;
        padding-left: 26px;
      }
    }
    .date_icon {
      &:before {
        position: absolute;
        content: "";
        background: url("/web_images/date_icon.svg") no-repeat;
        width: 15px;
        height: 16px;
        background-size: cover;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .team_icons {
      &:before {
        position: absolute;
        content: "";
        background: url("/web_images/user_icons.svg") no-repeat;
        width: 19px;
        height: 15px;
        background-size: cover;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.web_event_slider {
  .slick-track {
  }
  .slick-slide {
    width: 428px;
  }
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    // @include mediaq(768px){
    //   width: 20px;
    //   height: 20px;
    // }
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
    @include mediaq(768px) {
      font-size: 24px;
    }
  }
  .event_card {
    margin: 10px;
    border: 2px solid transparent;
    background: none;
    @include mediaq(480px) {
      margin: 15px;
    }
    &:hover {
      border: 2px solid $green;
      .event_card_titles {
        h3 {
          color: $green;
        }
      }
    }
    .event_card_desc {
      background: $white;
    }
  }
  .slick-prev {
    @include mediaq(768px) {
      left: -11px;
      z-index: 2;
    }
  }
  .slick-next {
    @include mediaq(768px) {
      right: -11px;
      z-index: 2;
    }
  }
}

// slick slider
.web_event_slider {
  .slick-dots {
    position: static; // bottom: 0;
    li {
      width: auto;
      height: auto;
      margin: 0;
      &.slick-active {
        button {
          &:before {
            color: $white;
          }
        }
      }
      button {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        &:before {
          font-size: 10px;
          color: $white;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// download app section
.web_download_section {
  position: relative;
  padding: 100px 0 125px;
  @include mediaq(768px) {
    padding: 40px 10px 20px;
  }
  p {
    color: $white;
    font-size: 14px;
    margin-bottom: 20px;
    font-family: $HvRegular;
  }
}

//  content section
.web_content {
  margin: 50px 0;
  .web_content_filter {
    margin-bottom: 15px;
    .tag_num {
      background: $green;
      display: inline-block;
      padding: 8px 15px;
      color: $white;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
    }
    h2 {
      font-family: $Hvbold;
    }
    .select_dropdown {
      margin-left: 30px;
      select {
        border: none;
        background: none;
        color: $dropdowntext;
        -webkit-appearance: none;
        padding: 5px 35px 5px 5px;
      }
    }
  }
}

.web_footer {
  p {
    padding: 20px 10px;
    font-size: 15px;
    font-family: $Hvmed;
    @include mediaq(768px) {
      font-size: 12px;
    }
  }
}

//  event list component
.web_eventOuterList {
  margin-top: -80px;
  position: relative;
  background: $white;
  border-radius: 10px;
  margin-bottom: 55px;
  @include mediaq(768px) {
    margin-bottom: 30px;
    margin-top: -40px;
  }
}
.comeSoon {
  box-shadow: 0 2px 10px rgba(223, 217, 219, 0.7);
  text-align: center;
  padding: 65px;
}
.web_eventlist_wrapper {
  box-shadow: 0 2px 10px rgba(223, 217, 219, 0.7);
  .web_eventList_img {
    width: 428px;
    border-radius: 4px 0 0 4px;
    overflow: hidden;
    background: $black;
    @include mediaq(991px) {
      width: 350px;
    }
    @include mediaq(991px) {
      width: 300px;
    }
    @include mediaq(768px) {
      margin: auto;
    }
    @include mediaq(480px) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 160px;
      min-height: 160px;
      object-fit: cover;
      border-radius: 4px 0 0 4px;
    }
    &.super8_league_img {
      img {
        min-height: 198px;
      }
    }
  }
  .web_eventList_content {
    width: calc(100% - 428px);
    @include mediaq(991px) {
      width: calc(100% - 360px);
    }
    @include mediaq(991px) {
      width: calc(100% - 300px);
    }
    @include mediaq(768px) {
      width: 100%;
      margin: 10px 0;
    }
    padding: 12px 9px;
    .web_eventList_data {
      width: 75%;

      @include mediaq(1024px) {
        width: 72%;
      }
      @include mediaq(576px) {
        width: 100%;
      }
      .web_eventList_logo {
        width: 109px;
        height: 109px;
        // padding: 20px;
        margin: 0 20px;
        border-radius: 50%;
        // background: $border2;
        @include mediaq(991px) {
          width: 80px;
          height: 80px;
          padding: 5px;
          margin: 0 10px;
        }
        @include mediaq(480px) {
          width: 60px;
          height: 60px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .web_eventList_detail {
        margin: 0 22px;
        width: calc(100% - 195px);
        @include mediaq(991px) {
          margin: 0 10px;
          width: calc(100% - 110px);
        }
        @include mediaq(480px) {
          width: calc(100% - 100px);
        }
        h1 {
          font-size: 26px !important;
          font-family: $Hvbold;
          color: $font-black;
          margin-bottom: 10px;
          @include mediaq(991px) {
            font-size: 20px !important;
          }
        }
        .web_eventList_icons {
          span {
            line-height: 23px;
            font-family: $Hvmed;
            color: $fontText3;
            margin-right: 30px;
            &:last-child {
              margin-right: 0;
            }
            @include mediaq(991px) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .web_eventList_join {
    width: 25%;
    @include mediaq(1024px) {
      width: 28%;
    }
    @include mediaq(576px) {
      width: 100%;
      margin: 10px;
      text-align: center;
    }
    h2 {
      font-size: 24px !important;
      line-height: 23px;
      font-family: $Hvbold;
      color: $green;
      @include mediaq(991px) {
        font-size: 16px !important;
      }
      span {
        font-size: 16px;
        font-family: $Hvmed;
        line-height: 23px;
        color: $webText;
        margin-left: 15px;
        @include mediaq(991px) {
          font-size: 14px;
        }
      }
    }
    button {
      font-weight: $weight500;
      width: 100%;
      max-width: 178px;
      margin-top: 10px;
      @include mediaq(576px) {
        min-width: unset;
        margin: 5px;
        width: 46%;
      }
      @include mediaq(360px) {
        width: 100%;
      }
    }
  }
}

.web_description_box {
  padding: 30px;
  background: $white;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0 2px 2px rgba(131, 146, 135, 0.1);
  @include mediaq(991px) {
    padding: 15px;
  }
  .web_desc_title {
    h3 {
      margin-bottom: 15px;
    }
  }
  .web_desc_two_title {
    margin-bottom: 10px;
  }
  .web_list_data {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1.5px solid $webborder;
    .web_list_data_box {
      width: 20%;
      padding-right: 10px;
      @include mediaq(768px) {
        width: 33.33%;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      @include mediaq(480px) {
        width: 50%;
      }
      @include mediaq(375px) {
        width: 100%;
      }
      label {
        margin-bottom: 5px;
        font-size: 14px;
        font-family: $HvRegular;
        color: $fontText3;
      }
      h3 {
        color: $fontText2;
        font-family: $Hvmed;
      }
    }
  }
  p {
    font-size: 14px;
    line-height: 23px;
    font-family: $HvRegular;
    color: $fontText3;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .web_list {
    li {
      font-size: 14px;
      line-height: 23px;
      font-family: $HvRegular;
      color: $fontText3;
      position: relative;
      padding-left: 20px;
      margin-bottom: 10px;
      &::before {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $darkpurple;
        content: "";
        left: 0;
        top: 10px;
      }
    }
  }
  a {
    font-family: $HvRegular;
  }
  .slick-dots {
    position: static; // bottom: 0;
    li {
      width: auto;
      height: auto;
      margin: 0;
      &.slick-active {
        button {
          &:before {
            color: $black;
          }
        }
      }
      button {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        &:before {
          font-size: 10px;
          color: $black;
          width: 100%;
          height: 100%;
          opacity: 0.3;
        }
      }
    }
  }
}

.web_description_fullborderbox {
  background: $white;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0 2px 2px rgba(131, 146, 135, 0.1);
  .web_desc_title {
    padding: 25px 30px;
    border-bottom: 1.5px solid $webborder;
  }
  .companyLogos {
    margin-bottom: 15px;
    img {
      margin-bottom: 15px;
      max-height: 47px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .web_description_box_20 {
    padding: 30px 20px 20px 20px;
  }
  .company_social_link {
    a {
      padding: 11px;
      font-family: $Hvmed;
      color: $white;
      border-radius: 4px;
      transition: 0.3s ease;
      margin-bottom: 10px;
      margin-right: 10px;
      display: inline-block;
      i {
        font-size: 18px;
        margin-right: 10px;
      }
    }
    .fblink {
      background: $fbcolor;
      border: 1px solid $fbcolor;
      &:hover {
        background: $white;
        color: $fbcolor;
        border: 1px solid $fbcolor;
      }
    }
    .twtlink {
      background: $twtcolor;
      border: 1px solid $twtcolor;
      &:hover {
        background: $white;
        color: $twtcolor;
        border: 1px solid $twtcolor;
      }
    }
    .emaillink {
      background: #30ae9b;
      border: 1px solid #30ae9b;
      &:hover {
        background: $white;
        color: #30ae9b;
        border: 1px solid #30ae9b;
      }
    }
  }
}

.web_table {
  background: $white;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0 2px 2px rgba(131, 146, 135, 0.1);
  .web_table_heading {
    padding: 30px;
  }
  table {
    th {
      background: $light_bg;
      color: $graydark;
      font-size: 14px;
      font-family: $Hvmed;
      font-weight: normal;
    }
    tr {
      &:hover {
        td {
          color: $green;
        }
      }
      &.active {
        color: $green;
      }
    }
    td {
      font-size: 14px;
      font-family: $Hvmed;
      font-weight: normal;
      color: $webtext2;
      img {
        width: 35px;
        height: 35px;
        max-width: 100%;
        object-fit: cover;
        display: inline-block;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
}

.web_hotel_slidermain {
  .web_hotel_slide {
    position: relative;
    &:after {
      box-shadow: inset 0px -80px 70px -30px $black;
      position: absolute;
      bottom: 0;
      z-index: 2;
      content: "";
      width: 100%;
      height: 220px;
    }
    img {
      border-radius: 4px;
      width: 100%;
      object-fit: cover;
      height: 394px;
    }
    .web_hotel_address {
      position: absolute;
      bottom: 0;
      z-index: 3;
      padding: 18px;
      h2 {
        color: $white;
        font-family: $Hvbold;
        margin-bottom: 5px;
      }
      p {
        color: $white;
        font-family: $HvRegular;
        line-height: 20px;
      }
    }
  }
}

// event Join page
.number_step {
  ul {
    @include display_flex;
    li {
      position: relative;
      width: 65px;
      span {
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: $lightgray;
        border-radius: 50%;
        @include display_flex;
        @include align_center;
        @include just_center;
        font-size: 18px;
        font-family: $Hvmed;
        color: $darkgray;
        box-shadow: 0 -1px 0px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 3;
        @include mediaq(576px) {
          width: 30px;
          height: 30px;
          font-size: 14px;
        }
      }
      &.active {
        &:before {
          background: $green;
        }
        span {
          color: $white;
          background: $green;
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        height: 6px;
        background: $lightgray;
        left: -30px;
      }
      &:after {
        // content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 6px;
        background: $lightgray;
        right: 0;
      }
      &:first-child {
        &:after,
        &:before {
          content: "";
          display: none;
        }
      }
    }
  }
}
.web_event_join {
  .nav-tabs {
    margin-bottom: 15px;
    .nav-item {
      width: 50%;
      .nav-link {
        border: 1px solid $border2;
        padding: 12px;
        text-align: center;
        &.active {
          border: none;
          background-color: $blue_btn !important;
          border: 1px solid $blue_btn !important;
          min-width: 130px;
          color: $white;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          line-height: 21px;
          font-family: $Roboto;
        }
      }
    }
  }
  .tab-content {
    border: none;
  }
}

.link_tag {
  color: $blue_btn;
  font-size: 16px;
  line-height: 21px;
  font-family: $Roboto;
  margin-right: 60px;
  display: inline-block;
  cursor: pointer;
  @include mediaq(576px) {
    margin-right: 20px;
    font-size: 14px;
  }
}

.image_prev_name {
  position: relative;
  border: 1px solid $webborder;
  padding: 13px;
  border-radius: 0 0 4px 4px;
  .image_name {
    width: calc(100% - 70px);
    font-family: $HvRegular;
    font-size: 16px;
    color: $blue_btn;
    margin-bottom: 0;
  }
}

.round_close {
  width: 30px;
  height: 30px;
  background: $weblightdark;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.web_hotel_venue_main {
  h1 {
    margin-bottom: 30px;
    font-family: $Hvbold;
    font-size: 24px !important;
    @include mediaq(991px) {
      font-size: 20px !important;
    }
  }
}

.web_join_title {
  padding: 20px 0;
  border-bottom: 1px solid $webborder;
  margin-bottom: 30px;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  h2 {
    font-size: 24px !important;
    font-family: $Hvbold;
    margin-bottom: 10px;
  }
  h1 {
    font-size: 24px !important;
    font-family: $Hvbold;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    color: $graydark;
  }
}

.web_manual_Tbl {
  border-radius: 4px;
  border: 1px solid $webborder;
  table {
    th {
      border-bottom: 2px solid $webborder;
      color: $graydark;
      font-weight: normal;
      font-family: $Hvmed;
      font-size: 16px;
    }
    td {
      font-family: $Hvmed;
      font-size: 16px;
    }
  }
  .add_roster {
    width: 100%;
    border-radius: 0 0 4px 4px;
  }
}

.create_event_row {
  .rc-time-picker {
    display: block;
  }
  .select_custom {
    select {
      font-size: 16px;
      color: $fontText3;
    }
  }
  .add_new_btn {
    p {
      margin-bottom: 0;
    }
  }
  padding-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  .form-group {
    margin-bottom: 0;
    position: relative;
  }
  .date_icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .react-datepicker-wrapper {
    display: block;
  }
  .button_type {
    width: 100%;
    outline: none;
    color: $fontText3;
    border-radius: 4px;
    border: 1px solid $border_1;
    background: none;
    font-size: 16px;
    font-family: $HvRegular;
    cursor: pointer;
    padding: 11px;
    position: relative;
    &.active {
      background-color: $blue_btn;
      color: $white;
      border: 1px solid $blue_btn;
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
  .file_input {
    position: relative;
    border: dashed 1px $border_1;
    background: $bodyBg;
    .image_upload_wrap {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      &.active {
        background: $white;
      }
      &.banner_upoad_img {
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
    padding: 100px;
    text-align: center;
    &.edit_profile_image {
      padding: 40px 20px;
    }
    i {
      font-size: 40px;
      color: $linkcolor;
    }
    p {
      color: $fontText3;
      font-size: 16px;
      font-family: $HvRegular;
      opacity: 0.5;
    }
    .img_file_input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .image_side_preview {
    .image_upload_wrap {
      border: 1px solid $border_1;
      position: relative;
      height: 250px;
      .remove_img {
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        right: -5px;
        top: -10px;
        width: 20px;
        height: 20px;
        background: $error;
        border-radius: 50%;
        color: $white;
        z-index: 2;
      }
      img {
        max-width: 100%;
        margin: auto;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  label {
    font-size: 15px;
    line-height: 20px;
    color: $fontText2;
    font-family: $Hvbold;
  }
  input {
    font-size: 18px;
    font-family: $HvRegular;
    color: $dropdowntext;
    &::-webkit-input-placeholder {
      color: $dropdowntext;
      font-size: 16px;
      font-family: $HvRegular;
    }
  }
  input[type="number"] {
    padding: 8px;
    font-family: $HvRegular;
    height: auto;
    border: 1px solid $border_1;
    border-radius: 4px;
    width: 100%;
    color: $fontText3;
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  input[type="text"],
  input[type="password"] {
    padding: 8px;
    height: auto;
    border: 1px solid $border_1;
    border-radius: 4px;
    width: 100%;
  }
  input.country_code_input {
    padding: 11px 11px 11px 76px;
  }
  .country_code {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    padding: 5px 8px;
    font-size: 14px;
    line-height: 20px;
    border: none;
    font-family: $HvRegular;
    color: $dropdowntext;
    outline: none;
  }
  .chk_box {
    width: 100%;
    input[type="checkbox"] {
      display: none;
      &:checked ~ label {
        background: $linkcolor;
        color: $white;
      }
    }
    label {
      padding: 11px;
      border: 1px solid $border_1;
      display: block;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      font-family: $HvRegular;
      color: $fontText3;
    }
  }
  .custom_radio {
    .radio_container {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      margin-bottom: 12px;
      margin-top: 10px;
      margin-left: 20px;
      cursor: pointer;
      font-size: 17px;
      &:first-child {
        margin-left: 0;
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .radiobtn {
          background: $green;
          &::after {
            display: block;
            transition: 0.2s ease;
            background: $white;
          }
        }
      }
      .radiobtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 12px;
        width: 12px;
        background-color: $fontText4;
        border-radius: 50%;
        &:after {
          top: 50%;
          left: 50%;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          transition: 0.2s ease;
          content: "";
          position: absolute;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  textarea {
    padding: 11px;
    height: auto;
    border: 1px solid $border_1;
    border-radius: 4px;
    width: 100%;
    font-size: 18px;
    font-family: $HvRegular;
    color: $fontText3;
    resize: none;
    &::-webkit-input-placeholder {
      color: $placeHolder;
      font-size: 16px;
      font-family: $HvRegular;
    }
  }
  .custom_rangpicker {
    .input-range {
    }
    .input-range__track--active {
      background: $green;
      height: 6px;
    }
    .input-range__slider {
      background: $green;
      border: 4px solid $white;
      box-shadow: 0 2px 10px rgba(229, 233, 242, 1);
    }
    .input-range__label--value {
      bottom: -35px;
      top: auto;
      .input-range__label-container {
        background: $green;
        color: $white;
        padding: 5px 8px;
        border-radius: 3px;
        &:before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 14px solid $green;
          top: -7px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
  div[data-radium="true"] {
    color: $white; // background: $green !important;
    div[data-radium="true"] {
      background: $green !important;
      font-size: 16px;
      font-family: $HvRegular;
    }
  }
  div[role="combobox"] {
    li {
      color: $black;
    }
  }
}

.custom_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  display: none;
  transition: 0.5s ease;
  z-index: 9999;
  &.open {
    display: block;
  }
  .popup_pad {
    padding: 0 10px;
    .inner_popup {
      padding: 25px;
      background: #fff;
      border-radius: 10px;
      position: relative;
      @include mediaq(768px) {
        padding: 15px;
      }
      .text-center {
        padding: 8px 5px 2px 10px !important;
      }
      h1 {
        margin-bottom: 45px;
        font-family: $Hvbold;
      }
      label {
        font-size: 15px;
        line-height: 20px;
        color: $font-black;
        font-family: $Hvmed;
      }
      .create_event_row {
        input {
          background: $inpbg;
        }
        textarea {
          background: $inpbg;
        }
        select {
          background: $inpbg;
        }
      }
      .close_popup {
        position: absolute;
        right: 15px;
        top: 20px;
        width: 16px;
        height: 16px;
        color: $graydark;
        cursor: pointer;
        font-size: 16px;
        z-index: 2;
      }
      .input_contents {
        width: 100%;
        .file_input {
          padding: 70px;
        }
      }
    }
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 98%;
    &.popup_510 {
      max-width: 510px;
      background: $white;
      border-radius: 10px;
      .delete_player_container {
        max-width: 230px;
        margin: auto;
        h2 {
          margin-bottom: 15px;
        }
        p {
          font-size: 16px;
          font-family: $Hvmed;
        }
      }
      .deletes_buttons {
        button {
          width: 50%;
          border: none;
          padding: 11px;
          font-size: 16px;
          font-family: $Hvmed;
          color: $btncolor;
          border-radius: 0 0 4px 4px;
          &.yes {
            background: $green;
            color: $white;
          }
          &:hover {
            // background: $green;
            // color: $white;
            opacity: 0.7;
          }
        }
      }
    }
    &.popup_540 {
      max-width: 540px;
      background: $white;
      border-radius: 10px;
    }
    &.popup_600 {
      max-width: 600px;
    }
    &.popup_310 {
      max-width: 310px;
      background: $white;
      border-radius: 10px;
    }
    &.popup_700 {
      max-width: 700px;
      background: $white;
      border-radius: 10px;
    }
    &.popup_710 {
      max-width: 700px;
      background: $white;
      border-radius: 10px;
    }
    &.popup_720 {
      max-width: 720px;
      background: $white;
      border-radius: 10px;
      .inner_popup {
        form {
          max-height: 500px;
          overflow: auto;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            background-color: $lightgray2;
            width: 7px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #555;
          }
        }
        .react-datepicker {
          font-size: 12px;
        }
      }
      .popup_fix_btn {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0;
        padding: 10px 0;
        background: $white;
        width: 97%;
        margin: auto;
        border-top: 1px solid $border_1;
        border-radius: 5px;
      }
    }
  }
}

.event_card_img {
  position: relative;
  img {
    max-width: 100%;
    // min-height: 200px;
    // max-height: 200px;
    width: 100%;
    object-fit: cover;
    margin: auto;
  }
  .event_card_address {
    display: block;
    font-size: 14px;
    line-height: 23px;
    font-family: $HvRegular;
    padding: 4px 10px 4px 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: $white;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px 0 0 0;
    &::before {
      content: "";
      background: url("/web_images/map_marker.svg") no-repeat;
      width: 12px;
      height: 16px;
      background-size: 100% 100%;
      left: 7px;
      z-index: 999;
      position: absolute;
      top: 7px;
    }
  }
}

//  joined event thankyou
.transaction_detail {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $webborder;
  .transaction_row {
    margin-bottom: 10px;
    label {
      font-size: 15px;
      line-height: 20px;
      font-family: $Hvbold;
      min-width: 150px;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
    }
  }
}

.web_hotel_venue {
  margin-bottom: 30px;
  // border-bottom: 1px solid $webborder;

  .venue_image {
    position: relative;
    &::after {
      box-shadow: inset 0px -80px 70px -30px #000;
      position: absolute;
      bottom: 0;
      z-index: 2;
      content: "";
      width: 100%;
      height: 120px;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 210px;
      min-height: 210px;
      object-fit: cover;
    }
  }

  .hotel_title {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: $white;
    z-index: 3;
    p {
      font-family: $HvRegular;
      color: $white;
    }
    .rating_row {
      .rating_btn {
        font-family: $HvRegular;
        color: $white;
        padding: 3px 20px;
        background: $rating;
        margin-right: 10px;
      }
      .facility {
        margin-bottom: 30px;
        ul {
          li {
            color: $fontText2;
            font-size: 14px;
            font-family: $Hvmed;
            position: relative;
            padding-left: 15px;
            margin-right: 10px;
            &::before {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: $fontText3;
              left: 0;
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
            &:first-child {
              padding-left: 0;
              &:before {
                display: none;
              }
            }
          }
        }
        p {
          color: $fontText3;
          font-family: $HvRegular;
        }
      }
    }
    .price_row {
      .price_detail {
        width: calc(100% - 140px);
      }
    }
  }
}

// Stripe payment
.stripe_payment {
  padding: 20px;
  background: #f6f9fc;
  label {
    display: block;
  }
  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
    &:hover {
      color: #fff;
      cursor: pointer;
      background-color: #7795f8;
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
    }
  }
}
.StripeElement {
  padding: 10px 10px;
  border: 1px solid #ddd;
  background: $white;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
}
.Toastify__toast-body {
  font-size: 14px;
  font-family: $HvRegular;
}
.text_center {
  position: absolute;
  align-items: center;
  left: 50%;
  font-weight: 800;
  opacity: 0.7;
}
.fqw_wrap {
  margin: 30px 0 50px;
}
.faq_title {
  margin: 30px 0;
  h1 {
    font-size: 36px;
    opacity: 0.8;
  }
}
.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 0.4rem;
  overflow: hidden;
  &__item {
    margin-bottom: 15px;
    & + & {
      // border-top: 1px solid #d5d5d5;
    }
  }
}

.accordion-item {
  $self: &;

  &--opened {
    #{ $self }__icon {
      transform: rotate(180deg);
    }

    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.2s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
      background: #fff;
      border: 1px solid #ddd;
      margin-top: -1px;
    }
    &.accordion-item__content {
    }
  }

  &__line {
    display: block;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ddd;
    background-color: #fff;
    z-index: 2;
    cursor: pointer;
    position: relative;
  }

  &__title {
    font-size: 16px;
    margin: 0;
    color: #8392a5;
    font-weight: 700;
    font-weight: normal;
    position: relative;
    width: 100%;
    padding: 15px 30px 15px 15px;
    &:after {
      position: absolute;
      content: "";
      width: 11px;
      height: 11px;
      top: 45%;
      border: 2px solid;
      transform: translateY(-50%) rotate(45deg);
      right: 20px;
      border-top: 0;
      border-left: 0;
      transition: 0.5s ease;
    }
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    // text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.2s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
    background: #fff;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.2s;
    padding: 15px 20px;
  }

  &__paragraph {
    margin: 0;
    font-size: 16px;
    color: #555;
    font-weight: 300;
    line-height: 1.3;
  }
}

.textTC {
  color: #8392a5;
  margin-bottom: 18px;
  line-height: 28px;
  font-size: 18px;
  text-align: justify;
  @include mediaq(991px) {
    line-height: 23px;
    font-size: 16px;
    padding: 0 15px;
  }
}

.not_allow_btn {
  cursor: not-allowed;
}
